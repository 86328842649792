.Thanks {
  padding-top: 84px;
  text-decoration: none;
}
.Thanks a {
  color: inherit;
  text-decoration: none;
}
.Thanks a:hover {
  font-weight: bold;
  text-decoration: underline;
}
.Thanks p {
  font-family: "Lato";
  font-size: 0.8em;
  white-space: nowrap;
}
.Thanks .url {
  font-size: 0.7em
}