@import url('https://fonts.googleapis.com/css?family=Lora&family=Lato&display=swap');

.Title h1{
  letter-spacing: 5px;
  font-family: "Lora";
  font-size: 4em;
  margin: 0;
}
.Title p{
  font-family: "Lato";

}