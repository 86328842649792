.GenreSplitter {
    width: 30%;
    padding-top: 48px;
    border-bottom: 1px solid lightgrey;

}
.GenreSplitter p {
    font-family: "Lato";
    margin: 0;
    padding-bottom: 8px;
    padding-left: 4px;
}